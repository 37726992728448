import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { media, Stack, ButtonReset } from '@tymate/margaret';
import BaseCard from 'components/Card';
import { renderHtml } from 'utils';
import { isEqual, snakeCase } from 'lodash';

const Inner = styled(Stack).attrs(({ variant }) => ({
  gutterSize: { default: 1, tablet: 2 },
  direction: {
    default: 'column',
    tablet: variant === 'homepage' ? 'column' : 'row',
  },
  alignX: variant === 'homepage' ? 'center' : 'flex-start',
}))`
  padding: ${({ theme }) => theme.spacing()};

  ${media.tablet`
    padding: ${({ theme }) => theme.spacing(2)};
  `}

  ${({ variant }) =>
    variant === 'homepage' &&
    css`
      text-align: center;
      padding-bottom: 42px !important;
    `}
`;

const Card = styled(BaseCard)`
  position: relative;
`;

const SkillIcon = styled(Stack).attrs({
  alignX: 'center',
  alignY: 'center',
})`
  height: auto;
  flex: 0 0 6em;
  padding: ${({ theme }) => theme.spacing(1.5)};
  border-radius: 100%;
  background-color: ${({ theme }) => theme.primary};
`;

const SkillIconImg = styled.img`
  width: 100%;
`;

const WrapperLink = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
`;

const SkillCard = ({ skill, variant, isInitialSkill }) => {
  const [isExpanded, setIsExpanded] = useState(isInitialSkill);
  const isTogglable =
    variant !== 'homepage' &&
    !isEqual(
      skill?.contentNode?.childMarkdownRemark?.htmlAst,
      skill?.contentNode?.childMarkdownRemark?.excerptAst,
    );

  return (
    <Card>
      <Inner variant={variant}>
        <SkillIcon>
          <SkillIconImg src={skill?.icon?.url} alt="" />
        </SkillIcon>
        <Stack
          direction="column"
          alignX={variant === 'homepage' ? 'center' : 'flex-start'}
        >
          <Card.Title>{skill?.title}</Card.Title>
          {isExpanded ? (
            <div>
              {renderHtml(skill?.contentNode?.childMarkdownRemark?.htmlAst)}
            </div>
          ) : (
            <div>
              {renderHtml(skill?.contentNode?.childMarkdownRemark?.excerptAst)}
            </div>
          )}
          {variant === 'homepage' && (
            <WrapperLink>
              <Card.MoreLink to={`/competences#${snakeCase(skill?.title)}`}>
                En savoir plus
              </Card.MoreLink>
            </WrapperLink>
          )}
        </Stack>
      </Inner>
      {isTogglable && (
        <Card.MoreLink
          as={ButtonReset}
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
        >
          {isExpanded ? 'Voir moins' : 'Voir plus'}
        </Card.MoreLink>
      )}
    </Card>
  );
};

export default SkillCard;
